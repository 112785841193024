body {
  min-height: "100vh";
  color: #12182b;
}

.dark-grey {
  color: #696974;
}

#root {
  min-height: "100vh";
}

a.primary {
  color: #12182b;
}

a.unstyled {
  text-decoration: none;
  color: inherit;
}

.color.primary {
  color: #3fb2e3;
  text-decoration: none;
}

.form-label {
  position: unset !important;
  transform: none !important;
}

.inputRounded.search .MuiOutlinedInput-root {
  height: 40px;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 16px;
  background: #2c3245;
  padding: 2px 2px 2px 40px !important;
}

.inputRounded input {
  color: #fff;
}

.inputRounded fieldset {
  border: none !important;
}

.form-error {
  color: red;
}

.default {
  color: inherit;
  text-decoration: none;
}

.secondry-green {
  color: #3bc963;
}

.center {
  justify-content: center;
}

.borderRadious {
  border-radius: 16px !important;
}

.pointer {
  cursor: pointer;
}

.normalize-anchor {
  text-decoration: none;
  color: #12182b;
}

.make-link {
  text-decoration: underline;
  cursor: pointer;
}

.color-white {
  color: #fff;
}

.dropzone-initalize.hover .styled-div {
  display: block;
}

.folder-row {
  position: relative;
}

.styled-div {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 20px !important;
  position: absolute;
}


.folder-row.active .styled-div {
  /* margin: -8px;
  border: dashed 2px #3ee5dd26; */
  border-radius: 10px;
}

.folder-row.active {
  background-color: #3ee5dd26;
  border-radius: 10px;
}

.file-row.active {
  background-color: #3ee5dd26;
  border-radius: 10px;
}

.outer-dropzone {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10px !important;
}

.outer-dropzone.active {
  /* margin: -24px; */
  border: solid 2px #3ee5dd26;
  border-radius: 10px;
}

.dropzone-initalize.hover .outer-dropzone {
  display: block;
}

.page-dropzone {
  border-radius: 10px;
  position: relative;
  min-height: 65vh;
  /* padding: 12px; */
}

.page-dropzone.active {
  background: #3ee5dd26;
}

.underline {
  text-decoration: underline !important;
}

.init-dropzone {
  background: rgba(62, 229, 221, 0.15);
  height: 150px;
  border: solid 2px #3ee5dd26;
  border-radius: 10px;
  position: relative;
}

.styled-div.active {
  display: block;
}

.dropable-file {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.notify-icon {
  margin-right: 10px;
}

.brand {
  width: 44px;
}

.introjs-tooltipbuttons {
  display: flex;
  align-items: stretch;
  justify-content: end;
}

.introjs-tooltip {
  min-width: 250px;
  max-width: 524px !important;
  width: 524px !important;
  border-radius: 14px !important;
}

.introjs-tooltip a {
  margin-left: 10px;
}

.introjs-skipbutton {
  font-weight: unset !important;
  font-size: 16px !important;
  position: absolute;
  bottom: 12px;
  left: 0px;
}

.introjs-tooltipbuttons {
  border: none !important;
}

.row-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.row-icon {
  width: 30px;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.introjs-nextbutton {
  background-color: #12182b !important;
  color: #fff !important;
  border-radius: 14px !important;
  padding: 12px 40px !important;
}

.introjs-prevbutton {
  background-color: #fff !important;
  color: #12182b !important;
  border-radius: 14px !important;
  padding: 12px 40px !important;
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 1px 0px,
    rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.intro-p {
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.introjs-tooltip-title {
  display: flex;
}

.introjs-tooltiptext {
  font-size: 16px;
}

.group-add-btn {
  position: relative;
}

.icon {
  width: 25px;
}

.icon-container {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container.margin {
  margin-right: 10px;
}

.icon-container img {
  width: 28px;
}

.MuiDialogContent-root:first-child {
  padding-top: 0px !important;
}

.MuiAutocomplete-popper {
  border-radius: 16px;
  color: #ffffff;
  margin-top: 6px;
}

.text-grey {
  color: #787e91;
}

.upload-file-progress-detail {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.f-42 {
  font-size: 42px !important;
  font-weight: 600;
}

.f-32 {
  font-size: 32px !important;
}

.f-12 {
  font-size: 12px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.default-icon {
  fill: #153340;
}

.primary-icon {
  fill: #787e91;
}

.secondry-icon {
  fill: #eaeaea;
}

.white-icon {
  fill: #fff;
}

.green-icon {
  fill: #67d786;
}

.danger-icon {
  fill: #D94E45;
}

.yello-icon {
  fill: #ECA53C;
}

.MuiAutocomplete-clearIndicator {
  color: #787e91 !important;
}

.btn-together {
  display: flex;
  width: 100%;
  justify-content: end;
}

.MuiBreadcrumbs-li h6.MuiTypography-h6 {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.f14 {
  font-size: 14px !important;
}

.header .fileName p {
  padding-left: 0px !important;
}

.fw-bold {
  font-weight: bold !important;
}

/* #feedback-modal .MuiRating-label {
  margin-left: 5px;
  margin-right: 5px;
} */

.MuiRating-root {
  color: #3ee5dd !important;
}

#feedback-modal {
  right: 10px;
  bottom: 10px;
  height: 320px;
  width: 364px;
  inset: calc(100% - 320px) calc(100% - 364px) !important;
}

#feedback-modal .MuiBackdrop-invisible {
  display: none;
}

.MuiTab-textColorInherit.Mui-selected {
  background-color: #3EE5DD;
  color: #12182B !important;
}

#upgrade-account .MuiTabs-indicator {
  display: none;
}

#upgrade-account .MuiTab-root {
  min-height: 28px !important;
}

#upgrade-account .MuiTab-root {
  border-right: none !important;
  padding: 0px !important;
}

#upgrade-account .MuiTab-root:first-child {
  border-radius: 6px 0px 0px 6px;
}

#upgrade-account .MuiTab-root:last-child {
  border-right: solid 1px #3EE5DD !important;
  border-radius: 0px 6px 6px 0px;

}

.plan-box {
  border: 1px solid #EAEAEA;
  border-radius: 24px;
  cursor: pointer;
}


.plan-box.active {
  border: 1px solid #3EE5DD;
  background: rgb(62 229 221 / 6%);
}

.plan-amount {
  border-right: 1px solid #EAEAEA;
}

.dark-mode .MuiDialog-paper {
  background: #12182B;
  color: #fff;
}

.MuiInputBase-multiline textarea {
  padding: 12px;
}

.plan-box.variant-light {
  border: solid 1px #787E91;
}

.oneline {
  display: flex;
  align-items: center;
}

.sidebar-drawer .top-notification {
  height: 24px;
}

.MuiInputBase-colorSecondary .MuiSelect-root {
  background-color: #2c3245;
  border: #2c3245 !important;
}

.floating-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

@media screen and (max-width: 480px) {
  #notistack-snackbar {
    flex: 1;
    align-items: start;
  }
}


@media screen and (max-width: 800px) {
  
  .stored-page .fileName,
  .home-page .fileName {
    width: 48% !important;
  }

  .stored-page .action,
  .home-page .action {
    width: 12% !important;
  }
}

@media screen and (max-width: 600px) {

  .fileName.pl-3 {
    padding-left: 0px;
  }

  .smFullWidth {
    width: 100%;
    margin-bottom: 10px !important;
  }

  .MuiAutocomplete-popper {
    border-radius: 16px;
    color: #ffffff;
    margin-top: 50px;
    width: 100vw;
  }
}

.introjs-tooltipbuttons .introjs-button {
  text-shadow: none !important;
}

.introjs-tooltipbuttons .introjs-button:focus {
  box-shadow: none;
  border: none;
}

.three-action-btn {
  height: 150px;
  width: 50px;
  position: absolute;
}

.intro-2 .introjs-helperLayer,
.intro-3 .introjs-helperLayer,
.intro-4 .introjs-helperLayer,
.intro-5 .introjs-helperLayer {
  border-radius: 30px !important;
}

.action-request,
.action-requested,
.action-download {
  padding: 10px;
}

.MuiInputBase-colorSecondary input {
  background-color: #2c3245 !important;
  border: #2c3245 !important;
  color: #ffffff;
}

.MuiButton-outlinedPrimary.type-2,
.MuiButton-outlinedPrimary.type-2.Mui-disabled,
.MuiButton-outlinedPrimary.type-2:hover {
  color: #3ee5dd;
  border: solid 1px #3ee5dd;
}

.MuiButton-containedPrimary.type-2,
.MuiButton-containedPrimary.type-2.Mui-disabled,
.MuiButton-containedPrimary.type-2:hover {
  background-color: #3ee5dd;
  color: #12182B;
  border: solid 1px #3ee5dd;
}

.MuiFormLabel-colorSecondary.Mui-focused {
  color: inherit !important;
}

#upgrade-account .MuiDialogContent-root {
  overflow-y: unset;
}

.feature {
  margin-right: 8px;
  width: 50px;
}

.search-input input {
  height: 20px;
  border: none;
}

.search-input .MuiInputBase-root {
  margin-bottom: 0px !important;
}

.btn-padding-none {
  padding: 0px !important;
}

.MuiTouchRipple-root {
  display: none;
}

.pl-3 {
  padding-left: 1rem;
}

.customGrid {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  transition: all .3s;
  user-select: none;
}

.fileName {
  flex: 0 0 auto;
  width: 50%;

  display: flex;
}

.fileName p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1rem;
}

.AddedDate {
  flex: 0 0 auto;
  width: 20%;
  text-align: left;
  padding: 0 1rem;
}

.ListIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 25px;
  text-align: left;
  /* padding: 0 1rem; */
}

.fileSize {
  flex: 0 0 auto;
  width: 20%;
  text-align: left;
  padding: 0 1rem;
}

.action {
  flex: 0 0 auto;
  width: 10%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.listHeading * {
  font-size: .9rem;
  color: #787e91;
}

.sidebar {
  flex-basis: 240px;
}

.fileNameContent small {
  display: none;

}

.fileNameContent {
  width: 100%;
  display: flex;
  align-items: center;
}

.stored-page .fileName,
.home-page .fileName {
  width: 54%;
}

.stored-page .action,
.home-page .action {
  width: 6%;
}

#upload-folder-button {
  width: 100%;
}

.MuiDrawer-root.MuiDrawer-modal {
  z-index: 1300 !important;
}

.default-textarea textarea {
  padding: 10px;
  background-color: #2C3245;
  border: solid 1px #2C3245;
  color: #fff;
}

@media screen and (max-width: 1100px) {
  .fileNameContent {
    width: calc(100vw - 370px);
  }

  .fileName {
    flex-grow: 1;
  }

  .AddedDate, .fileSize {
    display: none;
  }

  .fileNameContent small {
    display: flex;
  }

  .fileNameContent small * {
    display: block;
    font-size: .7rem;
  }
}

@media screen and (max-width: 600px) {
  .action {
    width: 50px;
  }

  .fileNameContent {
    width: calc(100vw - 120px);
  }

  .sidebar {
    flex-basis: 0px;
    width: 0px;
  }

  .sm-down {
    display: none;
  }

  .btn-together {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 600px) {
  .sm-up {
    display: none;
  }
}